.journal{
    $columnBluPantoneWidth: 180px;
    box-sizing: border-box;
    position: absolute;
    width: calc(100vw - $columnBluPantoneWidth);
    right: 0;
    &__bar{
        position: fixed;
        bottom: 0;
        left: 0;
        height: calc(100% - 0rem);
        width: $columnBluPantoneWidth;
        background-color: var(--color-dark);
    }
    &__nav{
        margin-top: 4rem;
        text-decoration: none;
        transition: all 0.2s;

        &__item{
            color: var(--color-dark);
            text-decoration: none;
            margin-top: 6rem;
            
        }
        :hover{
            transform: scale(1.04);
            transition: all 0.2s;
        }
    }
    h1{ 
        margin-top: 2rem;
        text-align: center;
        width: fit-content;
        margin-right: auto;
        margin-left: 6rem;
        font-size: 2.2rem;
        font-weight: 800;
        
    }
    h2{
        margin-top: 1rem;
        width: fit-content;
        margin-right: auto;
        margin-left: 6rem;
        font-weight: 400;
    }
    &__list{
        box-sizing: border-box;
        right: 0;
        width: 100%;
        padding: 2rem;
        
        display: flex;
        cursor: pointer;
        justify-content: space-evenly;
        flex-wrap: wrap;
        gap: 8rem;
        row-gap: 3rem;
        margin-top: 0.2rem;
        margin-bottom: 9rem;
        
        a {
            display: flex;
            flex-direction: column;
            align-items: center;

            min-width: 300px;
            max-width: 600px;
            color: red;
            text-decoration: none;  
            gap: 0.5rem;

            p{
              
                text-decoration: none;
                color: var(--color-dark);
                max-width: 200px;
                text-align: center;
                font-weight: 700;
            }
        }
        &__img{
            width: 10vw;
            object-fit: cover;
            aspect-ratio: 1/1.4;
          
        }
       
    }
   
}

@media (max-width: 768px) {
    .journal {
        width:  calc(100dvw - 10vw);
        left: 10vw;
        &__bar{
            height: 100% ;
            width: 10vw;
        }
        &__list {
            width:calc(100dvw - 10vw);
            left: 0;
            box-sizing: border-box;
           
            &__img{
                width: 40vw;
                object-fit: cover;
                aspect-ratio: 1/1.4;
            }
        }
    }
}

