.talents{
    &__cartIcon{
        position: absolute;
        right: var(--padding-inline);
        top: 2rem;
        cursor: pointer;
        width: 35px;
        img{
            width: inherit;
        }

    }
    &__wip{
 
        text-align: center;
        font-size: larger;
        margin-top: 17.3rem;
        margin-bottom: 13rem;
        margin-inline: 3rem ;
    }

    &__description{
        position: absolute;
        width: 100%;
        top: 10px;
        pointer-events: none;
        color: var(--color-gray);
        
        div{
            position: absolute;
            display: flex;
            align-items: center;
            height: 100vh;
            top: 0vh;
            right: 5rem;
            font-size: 1.1rem;
            
            max-width: 40vw;
        }
    }
    
    &__arrowIcon{
            position: absolute;
            left: var(--padding-inline);
            bottom: 2rem;
            width: 28px;
            opacity: 0.5;
    }
    &__list{
        height: 100vh; //100vh
        width: 94%;
        display: flex;
        flex-direction: column;
        align-items: start;
        justify-content: center;
        padding-left: 6%;
        gap: 1rem;
        

        &__active{
            transform-origin: left center;
            cursor: pointer;
               -webkit-user-select: none; 
                -ms-user-select: none; 
                user-select: none; 
                font-weight: 600;
                font-size: 1.5rem;
                transition: all 0.2s ease-out;
            transform: scale(1.4);
        }
        &__active:hover{
            // color: rgb(96, 96, 96);
            color: rgb(0, 25, 115);
            transform: scale(1.3);
            transition: all 0.2s ease-out;
        }
        
        &__item{
            transform-origin: left center;
            color: var(--color-dark);
           cursor: pointer;
           -webkit-user-select: none; /* Safari */
            -ms-user-select: none; /* IE 10 and IE 11 */
            user-select: none; /* Standard syntax */
            font-weight: 600;
            font-size: 1.5rem;
            transition: all 0.2s ease-out;

           
        }
        &__item:hover{
            // color: rgb(96, 96, 96);
            color: rgb(0, 25, 115);
            transform: scale(1.04);
            transition: all 0.2s ease-out;
        }
    }
}

.shop{
    display: flex;
    align-items: center;
    flex-direction: column;
    h1{
        padding: 2rem;
        font-size: 3rem;
    }
    &__cartIcon{
        position: absolute;
        right: 2rem;
        top: 2rem;
        cursor: pointer;
    }
    &__list{
        
        box-sizing: border-box;
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-wrap: wrap;
        width: 90%;
        max-width: 1200px;
        margin: 0 auto;
        gap: 2rem;
        row-gap: 4rem;
        margin-bottom: 25vh;


    }
    &__product{
        cursor: pointer;
        &__img{
          
            img{
                height: 300px;
                width: 300px;
                overflow: hidden;
                -webkit-transition: .2s ease-in-out;
                transition: .2s ease-in-out;
            }
            height: 300px;
            width: 300px;
            
            overflow: hidden;
           
            
        }

        &__img:hover{
            img{
                -webkit-transform: scale(1.08);
                transform: scale(1.08);
                -webkit-transition: .3s ease-in-out;
                transition: .3s ease-in-out;
            }
           
        }
    }

    
}


@media (max-width: 710px) { 
    .talents{
        &__arrowIcon{
            position: absolute;
            left: 48vw;
            bottom: 6rem;
            width: 28px;


        }
        &__list{
            justify-content: start;
            padding-top: 8rem;
            gap: 0.2rem;
        }
        &__description{
            div{
                margin-top: 3rem;
                left: 26px;
                top: 10px;
                font-size: 0.9rem;
                width: calc(100% - 3rem);
                max-width: 100%;
            }
        }
    }
    .shop{
        &__list{
            justify-content: center;
        }
        &__product{
            &__img{
          
                img{
                    height: 90vw;
                    width: 90vw;
       
                }
                height:90vw;
                width:90vw;

               
                
            }
    
            &__img:hover{
                img{
                    -webkit-transform: scale(1.08);
                    transform: scale(1.08);
                    -webkit-transition: .3s ease-in-out;
                    transition: .3s ease-in-out;
                    z-index: -1;
                }
               
            }
        }
    }

 }
